import React, { useState, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'
import axios from 'axios'
import queryString from 'query-string'
import {Base64} from 'js-base64'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import Container from '@material-ui/core/Container'
import NoSsr from '@material-ui/core/NoSsr'

const resetPwSuccessPageUseStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    container: {
        padding: theme.spacing(2),
        margin: 'auto',
        maxWidth: 800,
        overflow: 'auto',
        maxHeight: '60vh',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        maxWidth: 800,
    },
    drawer: {
        maxWidth: '100%',
        maxHeight: '25%',
        flexShrink: 0,
    },
    drawerPaper: {
        height: '25%',
    },
    fullWidthTxt: {
        width: '60vw',
    },
    button: {
        marginTop: '16px',
        marginRight: theme.spacing(1),
    },
    card: {
        minWidth: 275,
    },
    cardMargin: {
        marginTop: '1rem'
    },
    link: {
        margin: theme.spacing(1),
    },
    outputBox: {
        wordBreak: 'break-all'
    },
    error: {
        color: 'red'
    },
    textCenter: {
        textAlign: "center"
    }
}));

const ResetPwSuccessPage = ({ location }) => {
    const classes = resetPwSuccessPageUseStyles();

    return (
        <NoSsr>
            <Container className={classes.root}>
                <Paper className={classes.container} elevation={0}>
                    <Grid item xs={12}>
                        <h1>Aither Member</h1>
                    </Grid>
                    <Grid item xs={12}>
                        <h3>Reset Password</h3>
                        <p className={classes.textCenter}>Reset Success.</p>
                    </Grid>
                </Paper>
            </Container>
        </NoSsr>
    )
}

export default ResetPwSuccessPage
